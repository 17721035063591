import sjcl from "sjcl";

function sha256(message) {
  const myString = message;
  const myBitArray = sjcl.hash.sha256.hash(myString);
  const myHash = sjcl.codec.hex.fromBits(myBitArray);
  return myHash;
}

function getDateTime() {
  return new Date().toLocaleString();
}

export default {
  sha256,
  getDateTime,
};
